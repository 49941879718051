#CardForm{
    width: 100%;
    height: 100%;
    margin: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px;
}
#CardForm i{
   font-size: 1rem;
   margin: 5px 0;
}
.w500{
    max-width: 500px;
    width: auto;
    height: 100%;
    margin: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px;
}
.w500 h3, #CardForm h3{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    padding: 2%;
    justify-content: center;
    align-items: center;
    align-content: center;
}
 #CardForm .left, #CardForm .right{
width: 50%;
padding: 10px;
}

 #CardForm .right{
    width: 100%;
    }
#CardForm form{
    width: 100%;
    height: auto;
    padding: 10px;
    border:solid 0px #09f
}

.full-flex{
    display: flex;
    flex-direction: column;
}
.half-flex{
    display: flex;
    flex-direction: row;
    gap: 10px;   
}
.w500, #CardForm form input{
    width: 100%;
    padding:10px 20px;
    margin: 4px 0;
    border:solid 1px #09f
}
.btn-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

@media screen and (max-width:870px){
    .w500, #CardForm{
        width: 90%;
        height: 70vh;
        gap: 10px;
    } 
    #CardForm .left, #CardForm .right{
        width: 50%;
        padding: 5px;
        }
}
/* @media screen and (max-width:640px){
    #CardForm{
        display:flex;
        flex-direction:column-reverse;
        width: 400px;
        height: 70vh;
        gap: 20px;
        padding: 20px;
    } 
    #CardForm .left, #CardForm .right{
        width: 100%;
        padding: 5px;
        }
} */

@media screen and (max-width:640px){
    #CardForm{
        display:flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 70vh;
        gap: 20px;
        padding: 20px;
    } 
    #CardForm .left, #CardForm .right{
        width: 100%;
        padding: 5px;
        }
}


@media screen and (max-width:460px){
    #CardForm{
        display:flex;
        flex-direction: column;
        width: 99%;
        height: 70vh;
        gap: 20px;
        padding: 20px;
    } 
    #CardForm .left, #CardForm .right{
        width: 100%;
        padding: 5px;
        }
}

