
/* Settings Navigation navs*/
li{
    list-style-type: none;
}
.settings-nav{
    display: flex;
    width:100%;
    height: 70px;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
    background-color: var(--dark-blue);
   }
   .settings-menu{
    display: flex;
    padding: 10px;
   }
   .settings-menu li{
    margin: 5px;
    padding: 10px 20px;
    font-family: "Montserrat" sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    transition:all 0.3s;
    color: var(--light-blue);
    color: #fff;
}
.settings-menu li:hover{
    background-color: var(--light-blue);
    border-radius: 20px;
   }
 
.links li{
    list-style-type: none;
    transition: 0.3s;
    font-weight: 900;
    font-size: 16px;
    color: var(--orange);
}
.links li:hover{
    list-style-type: none;
    color: var(--light-blue);
}
.upload-file-input{
    margin-top: 30px;
margin-bottom: 30px;
border: dashed 2px coral;
color: coral;
opacity:0;
font-size: 16px;
overflow: hidden;
z-index: 2;
position: relative;
}
.upload-label{
    width: 100%;
    border: dashed 2px coral;
    height: 50px;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    font-size: 16px;
    margin-top: -75px;
    background-color: #fff;
    z-index: 1;
}
/* Navigation navs @ media Queries 490*/
@media screen and (max-width:756px){
    
.settings-nav{
    display: none;
}
.settings-menu{
    display: none;
   }
   .settings-menu li{
    margin: 5px;
    padding: 10px 20px;
    font-family: "Montserrat" sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    transition:all 0.3s;
    color: var(--light-blue);
    color: #fff;
}
.settings-menu li:hover{
    background-color: var(--light-blue);
    border-radius: 20px;
   }
 
.links li{
    list-style-type: none;
    transition: 0.3s;
    font-weight: 900;
    font-size: 16px;
    color: var(--orange);
}
.links li:hover{
    list-style-type: none;
    color: var(--light-blue);
}
 
}  


.settings-dashboard{
    margin-top: 0px;
    margin-bottom: 20px;
    background-color:#fff;
    display: grid;
}

.settings-dashboard .settings-container{
    width:1200px;
    min-height: 100vh;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5;
}
.settings-dash-right, .settings-dash-left{
    padding:80px 100px;
    background-color: #fff;
  }
  
  .settings-dash-left-bg{
      background-color:antiquewhite;
    }
.light-gray-bg{
  background-color: rgb(223, 230, 243);  
}

.small-card, .medium-card, .settings-large-card{
    width: 400px;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 40px auto;
}

.flex-out-bio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.bio{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0px;
}
.address{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.settings .small-card img, .settings .medium-card img, .settings-large-card img{
    width:100px;
    height: 100px;
    border-radius: 50%;
    background-color: aquamarine;
    padding: 5px;
}
.small-icon{
    width:30px;
    height: 30px;
}

.small-card h3, .medium-card h3, .settings-large-card h3{
   font-size: 20px;
   font-weight: 800;
   margin: 10px 0;
   color: var(--dark-blue);
}
.card-type h3{
    font-size: 30px;
    font-weight: 800;
    margin: 10px 0;
    color: var(--dark-blue);
 }
.medium-card p, .settings-large-card p{
display: flex;
justify-content: space-between;
margin: 30px 10px;
}
.settings-large-card p{
    line-height: 25px;
}

.medium-card span.title{
font-weight: 600;
font-size: 18px;
}

.medium-card span .signup{
   cursor: pointer;
    }

.flat-card{
    display: flex;
    gap: 10px;
    margin: 50px auto;
}
.flat-card-grid{
    display: grid;
    margin: 20px 0;
    padding: 10px;
    gap: 10px;
    background-color: antiquewhite;
    border-radius: 10px;
}
.card-type{
    display: flex;
   margin: 20px 0;
   justify-content: center;
   justify-items: center;
   align-items: center;
   margin: auto;
   gap: 10px;
}
.flat-card-details{
    display: flex;
    justify-content: space-around;
    gap: 10px;
}
.flat-card-details .card-figure{
padding:0px;
border-radius: 0px;
background-color: aquamarine;
width:70px;
}
.flat-card-details .card-number{
padding: 10px;
font-size: 16px;
font-weight: normal;
letter-spacing:2px;
}


@media screen and (max-width:992px){
    .settings-dashboard .settings-container{
        width:100%;
        min-height: 100vh;
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5;
    }
    .settings-dash-right, .settings-dash-left{
        padding:20px;
        background-color: #fff;
      }
  
    .flex-out-bio{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        padding: 20px 0;
    }
    .bio{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 0px;
    }
}



@media screen and (max-width:835px){
    .settings-dashboard .settings-container{
        width:100%;
        min-height: 100vh;
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
    }
    .settings-dash-right, .settings-dash-left{
        padding:10px;
        background-color: #fff;
      }
  
    .flex-out-bio{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 2px;
        padding: 10px;
    }
    .bio{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 0px;
    }
}



@media screen and (max-width:755px){
    .settings-dashboard .settings-container{
        width:100%;
        min-height: 100vh;
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }
    .settings-dash-right, .settings-dash-left{
        padding:10px;
        background-color: #fff;
      }
  
      .small-card, .medium-card, .settings-large-card{
        width: 90%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 40px auto;
    }
    

    .flex-out-bio{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 2px;
        padding: 10px;
    }
    .bio{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 0px;
    }
}


@media screen and (max-width:480px){
    .settings-large-card {
        width:100%;
        height: auto;
        margin: 20px auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }
    .flex-out-bio{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 2px;
        padding: 10px;
    }
    .bio{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 0px;
    }
}


@media screen and (max-width:440px){
    .settings-large-card {
        width:100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 20px;
        padding: 20px;
    }
}
