/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .box {
    position: relative;
    max-width: 450px;
    margin: 0 auto;
    height: auto;
    min-height: 25vh;
    height: auto important;
    margin-top: calc(100vh - 80vh - 0px);
    background: #fff;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #999;
    overflow: auto;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(36% - 4.5%);
    top: calc(100vh - 77vh - 25px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
 
/* Navigation navs @ media Queries 1500*/
@media screen and (min-width:1500px){
 
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(42% - 4.5%);
    top: calc(100vh - 78vh - 25px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
}