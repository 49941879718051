@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --career: #2fd366;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --grey: #666666;
  --gray-dark: #343a40;
  --primary: #17449e;
  --secondary: #fff;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --dark-blue: #1b4d89;
  --light-blue: #0099ff;
  --button-light-green: #28a745;
  --button-dark-green: #28a745;
  --nav-bg: #20c997;
  --mint-green2: #92e3a91a;
  --mint-green: #92e3a9;
  --h1: #333;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Montserrat" sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*, body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.3s;
  font-weight: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* user dashboard activities nav menu */
.act_btn{
  width: 30px;
  height: 30px;
  background: var(--orange);
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.act_btn:hover{
  cursor: pointer;
  background: var(--nav-bg);
}
.act_open{
  display: block;
  margin-top: -135px;
  padding: 8px;
  transition: all 1ms;
  position: absolute;
  background: white;
  width: 370px;
  z-index: 5;
}
.act_close{
  display: none;
}
.ul_nav{
  position: relative;
}
.act_menu_link{
  display: grid;
  gap: 10px;
}
ul.act_ulli li{
margin: 8px 0;
color: var(--orange);
}

.flashResponse {
  width: 100%;
  margin: auto;
  padding: 5px;
  word-wrap: break-word;
}

span .alert-warning,
.alert-warning {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
  color: #a94442;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #f2dede;
  border-color: #ebccd1;
  border-radius: 5px;
}

.txt-red {
  color: #a94442;
}

.txt-green {
  color: #048119;
}

.fllex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.overflow-hidden{
  overflow: hidden;
  word-break: break-word;
}
.flex-between{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: bold;
  border-bottom: solid 1px var(--orange);
}
.alert-success {
  padding: 10px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
}

.cursor {
  cursor: pointer;
}

.link_orange {
  color: var(--orange);
  cursor: pointer;
  transition: 0.3s;
}

.link_orange:hover {
  color: var(--light-blue);
}

.loader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  gap: 20px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  color: var(--dark-blue);
  font-weight: 800;
}

/* Navigation navs*/
.nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: 0% 7%;
}

.logo {
  display: flex;
  width: auto;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: var(--dark-blue);
  gap: 5px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.logo img {
  width: 30px;
}

.menu .menu-ul {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.menu > li {
  list-style-type: none;
  margin-top: 10px;
  text-transform: uppercase;
}

.text_link_gray{
  color: var(--gray-dark);
  font-size: 16px;
  transition: 0.3s;
  margin: 10px;
}

.text_link_gray:hover{
  color: var(--orange);
  text-decoration: underline;
}
.login {
  padding: 10px 15px;
  color: var(--nav-bg);
  font-size: 16px;
  text-decoration: none;
  border-radius: 20px;
  border: solid 1px var(--nav-bg);
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;
}

.login:hover {
  border-radius: 20px;
  background-color: var(--nav-bg);
  color: white;
}

.signup {
  padding: 10px 20px;
  background-color: var(--orange);
  border: solid 1px var(--orange);
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
}

.signup:hover {
  border-radius: 20px;
  background-color: #fff;
  color: var(--orange);
  border: solid 1px var(--orange);
}

.hamburger-container {
  color: var(--orange);
  font-weight: bold;
  display: none;
  transition: 0.3s;
}

.loader-text-color {
  color: var(--orange);
}

/* Navigation navs @ media Queries*/
@media screen and (max-width: 752px) {
  .nav {
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    height: auto;
    padding: 20px;
    gap: 20px;
  }

  .logo {
    display: flex;
    width: auto;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: var(--dark-blue);
    gap: 1px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .logo img {
    width: 30px;
  }

  .menu {
    display: flex;
    gap: 10px;
  }

  .toggle-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 60px;
  }

  .toggle-div {
    display: flex;
    gap: 50px;
  }

  .menu > li {
    list-style-type: none;
    margin-top: 10px;
  }
}

/* Navigation navs @ media Queries 504*/
@media screen and (max-width: 504px) {
  .hero-container {
    padding: 3%;
  }

  .hamburger-container {
    color: var(--orange);
    font-weight: bold;
    display: block;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    transition: 0.3s;
  }

  .logo {
    display: flex;
    width: auto;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: var(--dark-blue);
    gap: 3px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .logo img {
    width: 30px;
  }

  .menu {
    display: none;
  }

  .menu-open {
    display: block;
    transition: 0.3s;
  }

  .menu-close {
    display: none;
    transition: 0.3s;
  }

  ul.menu-ul {
    top: 70px;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    border-top: solid 0px #0e53a8;
    transition: 0.3s;
    z-index: 3;
  }

  ul.menu-ul li {
    margin-top: 30px;
    padding: 50px;
  }

  .toggle-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 60px;
  }

  .toggle-div {
    display: flex;
    gap: 50px;
  }

  .menu > li {
    list-style-type: none;
    margin-top: 10px;
  }
}

@media screen and (max-width: 435px) {
  ul.menu-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .login {
    padding: 8px 15px;
    border-radius: 20px;
    margin: 0px;
  }

  .signup {
    padding: 8px 15px;
    border-radius: 20px;
    margin: 0px;
  }
}

/* HERO CONTAINER */
.hero-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
  padding: 5%;
}

.hero-container-dark-blue {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
  padding: 5% 1%;
  background-color: var(--dark-blue);
}

.hero-container h1 {
  color: var(--h1);
}

.xhero {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100%;
  margin: auto;
  padding: 100px 50px;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  gap: 50px;
}

.left,
.right {
  width: 50%;
  font-size: 30px;
  font-weight: 400;
  border: #007bff solid 0px;
  padding: 0 3%;
}

.left h1,
.right h1 {
  font-size: 35px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

.left {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.right {
  justify-content: center;
  align-items: center;
}

.hero-image {
  width: 100%;
  position: relative;
}

p.parag {
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
}

@media screen and (max-width: 992px) {
  .hero-container {
    width: 100%;
    padding-top: 20px;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .left,
  .right {
    width: 100%;
  }

  .left h1,
  .right h1 {
    font-size: 40px;
    padding-bottom: 10px;
  }

  .hero-image {
    width: 100%;
    margin: auto;
    padding: auto;
    position: relative;
  }

  .hero-image img {
    width: 90%;
    margin: auto;
    padding: 10%;
  }

  p.parag {
    font-size: 20px;
    font-weight: normal;
    line-height: 25px;
  }
}

.illustrator-container {
  width: 100%;
  min-height: 600px;
  height: auto;
  background-color: var(--dark-blue);
  border-top-left-radius: 60px;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 100px 50px;
  transform: skewY(-2deg);
}

.illustrator-container::before {
  content: "";
  width: 40px;
  height: 40px;
  background-color: var(--dark-blue);
  position: absolute;
  top: -39px;
  right: 0;
  z-index: -2;
}

.illustrator-container::after {
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: -1;
}

.illustrator-container div,
illustrator div .right,
illustrator div .left {
  transform: skewY(1deg);
}

.illustrator {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  margin: auto;
  padding: 100px 50px;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: #007bff solid 0px;
  gap: 50px;
}

.illustrator .left,
.illustrator .right {
  width: 50%;
  font-size: 30px;
  font-weight: 600;
  border: #007bff solid 0px;
  padding: 0 20px;
}

.illustrator .left h1,
.illustrator .right h1 {
  font-size: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}

.illustrator .right {
  justify-content: center;
  align-items: center;
}

.illustrator-image {
  width: 500px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .illustrator {
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .illustrator .left,
  .illustrator .right {
    width: 100%;
  }

  .illustrator .left h1,
  .illustrator .right h1 {
    font-size: 40px;
    padding-bottom: 10px;
  }

  .illustrator-image {
    width: 100%;
    margin: auto;
    padding: auto;
    position: relative;
  }

  .illustrator-image img {
    width: 80%;
    margin: auto;
    padding: 1%;
  }
}

@media screen and (max-width: 768px) {
  .illustrator {
    width: 100%;
    padding: 0px;
  }

  .illustrator .left,
  .illustrator .right,
  .right,
  .left {
    width: 100%;
    padding: 0px;
  }
}

/* Get started form */
.get-started-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 2px;

  border: solid 2px var(--dark-blue);
  border-radius: 20px;
  width: 500px;
  margin: 25px auto;
  height: 45px;
}

textarea.text-arial-styled,
textarea.text-arial-styled:focus,
textarea.text-arial-styled:hover {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  height:0;
position:absolute;
z-index: -1;
opacity: 0.1;
}

input.text-arial-styled,
input.text-arial-styled:focus,
input.text-arial-styled:hover {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  height:0;
position:absolute;
z-index: -1;
opacity: 0.1;
}

input.text-arial-styled-show,
input.text-arial-styled-show:focus,
input.text-arial-styled-show:hover {
  border-style: none;
  border-color: Transparent;
  background-color: transparent;
  overflow: auto;
}

.get-started-form input {
  width: 310px;
  border-radius: 20px;
  padding: 15px;
  border: none;
}

input:focus {
  outline: none;
}

.get-started-form input:focus,
.get-started-form input:active {
  outline: none;
  background-color: none;
}

.get-started-form button {
  width: 150px;
  border-radius: 20px;
  padding: 10px 25px;
  background-color: var(--orange);
  border: solid 1px var(--orange);
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}

.get-started-form button:hover {
  background-color: #fff;
  color: var(--orange);
  border: solid 1px var(--orange);
}
.currency-converter-container-2{
  width:200px;
  color: var(--orange);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  position: relative;
  background-color: white;
  background: white;
}
.currency-converter-container {
  color: var(--orange);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  position: relative;
  background-color: white;
  background: white;
}
.border-2{
  border: solid 2px var(--orange);
}
/* FOOTER */

.footer-container,
.testimonial-container,
.feature-container,
.copyright-container,
.trust-container {
  width: 100%;
  min-height: 50px;
  height: auto;
  justify-content: center;
  margin: auto;
}

.copyright-container {
  display: flex;
  width: 100%;
  min-height: 50px;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 15px 10px;
}

.copyright {
  font-size: 14px;
}

.footer-container {
  border-top: solid 70px var(--dark-blue);
}

.testimonial-container {
  background: linear-gradient(
    to right,
    var(--dark-blue),
    var(--orange),
    var(--dark-blue)
  );
}

.feature,
.trust {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 100px 5px;
  justify-content: center;
  align-items: center;
}

.footer,.card-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 100px 50px;
  justify-content: space-around;
  justify-items: center;
  align-items: self-start;
  gap: 50px;
}

.card-container {
  justify-content: center;
}

.testimonial {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80vh;
  height: auto;
  margin: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 50px;
  padding: 0px 10px;
}

.testimonial-card {
  display: grid;
  width: 100%;
  min-height: 300px;
  height: auto;
  padding: 0px;
  transition: all 0.3s ease;
  color: #ffffff;
}

.tc-top {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  padding: 1px;
}

q {
  line-height: 30px;
}

.tc-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}

.tc-photo {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  padding: 5px;
}

.tc-photo .photo-fit {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.name_title .name {
  font-size: 25px;
  font-weight: bolder;
  padding-bottom: 15px;
}

.testimonial-slider-nav {
  font-size: 40px;
  font-weight: bold;
  color: #ddd;
  cursor: pointer;
}

.trust-card-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  margin: 20px 0;
  padding: 10px 0px;
  justify-content: space-around;
  justify-items: center;
}

.trust-card {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  padding: 7px;
  gap: 10px;
  margin: 0 50px;
}

.trust-fit {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.footer h3 {
  font-weight: 700;
  color: var(--dark-blue);
  border-bottom: solid var(--nav-bg) 2px;
  padding: 5px 0;
}

.footer .link {
  display: grid;
  margin: 10px;
  text-decoration: none;
  color: var(--dark-blue);
  transition: 0, 3s;
  font-size: 12px;
}

.social {
  font-size: 20px;
}

.footer .link:hover {
  color: var(--nav-bg);
}

@media screen and (max-width: 720px) {
  .get-started-form {
    display: flex;
    flex-direction: column;
    justify-content: right;
    gap: 0px;
    clear: both;
    margin-bottom: 40px;
    width: 90%;
  }

  .get-started-form input {
    width: 96%;
    padding: 5px 1px;
  }

  .get-started-form button {
    width: 100%;
    margin-top: 20px;
  }

  .currency-converter-container {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .get-started-form {
    display: flex;
    flex-direction: column;
    justify-content: right;
    gap: 5px;
  }

  .get-started-form input {
    width: 300px;
    padding: 15px;
  }

  .get-started-form button {
    width: 300px;
  }
  .currency-converter-container {
    width: 100%;
  }
}

@media screen and (max-width: 993px) {
  .footer,
  .testimonial,
  .feature-container,
  .card-container,
  .trust-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5;
  }
}

@media screen and (max-width: 768px) {
  .footer,
  .testimonial,
  .feature-container,
  .card-container,
  .trust-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5;
  }
  .currency-converter-container {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .footer,
  .testimonial,
  .card-container,
  .trust-card-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1;
  }
}

@media (min-width: 600px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Stripe Payment Intent Form */

main {
  width: 480px;
  margin: auto;
  margin-top: 50px;
}

form > * {
  margin: 10px 0;
}

main button {
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

button:hover {
  filter: contrast(115%);
}

button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}

button:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
}

a {
  color: var(--accent-color);
  font-weight: normal;
}

small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  display: none;
  /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

/* Feature cards */
.features-container h1,
.feature h1,
.trust h1 {
  font-size: 20px;
  font-weight: bold;
  text-wrap: nowrap;
  padding: 1px 10px;
}

.features-container {
  width: 100%;
  min-height: 600px;
  height: auto;
  margin: auto;
  gap: 50px;
}

.card-container {
  /* display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 2px;
  gap: 10px; */

  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.feature-card {
  width: 300px;
  height: 320px;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: all 0.3s ease;
}

.feature-card:hover {
  box-shadow: 0 20px 30px -10px rgb(20 20 33 / 5%),
    0 10px 20px -10px rgb(0 0 0 / 20%);
}

.feature-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 40px;
  color: var(--dark-blue);
  font-weight: bolder;
  padding-top: 5px;
  padding-bottom: 10px;
}

.feature-card-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--dark);
}

.feature-card-body {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: normal;
  padding-top: 10px;
  line-height: 23px;
  justify-content: center;
  margin-top: 10px;
  color: #545454;
}
