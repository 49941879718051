.pages-container h1, .pages-container h2{
color: #010101;
}
.pages-container h6, .pages-container h2{
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
    color: var(--dark-blue);
}

.pages-container h3{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 25px;
}
.pages-container h4{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}
.pages-container{
    width: 900px;
    height: auto;
    border: solid 0px #555;
    margin: 100px auto;
    padding: 50px;
  }
  .pages-container h1{
    font-size: 25px;
    width: 50%;
  font-size: 30px;
  font-weight: 600;
  border: #007bff solid 0px;
  padding: 0 20px;
  }
  .pages-container p{
    margin: 50px auto;
  }
.pages-container p.parag{
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
}
.pages-container> p.parag li{
  padding-left: 16px;
  color: #686b6e;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: serif;
  font-size: 20px;
}
.blue{
  width: 100px;
  background-color: #0099ff;
  color: white;
  align-items: center;
  align-content: center;
  margin: auto;
}

@media screen and (max-width: 900px) {
    .pages-container{
    width: 100%;
    padding: 20px;
    }
}