.bb-1{
border-bottom: solid 1px var(--dark-blue);
}

.my_flex{
    width: 100%;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 10px;
}
.dash-nav{
    display: flex;
    flex-direction: row;
    width:100%;
    height: 70px;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
   }

   .dash-logo{
    display: flex;
    width: auto;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: var(--dark-blue);
    gap: 5px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .dash-logo img{
    width: 30px;
  }
  .dash-menu{
    display: flex;
    flex-direction: row;
    gap:20px;
    z-index: 5;
  }
  .dash-menu-ul div{
    display: flex;
    flex-direction: row;
    gap:20px;
    z-index: 5;
  }
  .dash-menu li{
    list-style-type: none;
    margin-top: 10px;
    text-transform: capitalize;
  }
  .logout-icon{
    display: none;
   } 
   .logout-button{
    display: block;
   } 
   
.settings-menu-show li{
    display: none;
}
@media screen and (max-width:756px){
    .hamburger-container{
        color:var(--orange);
        font-weight: bold;
        display: block;
      }
    
.dash-nav{
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    padding: 0px 10px;
   }

.dash-logo{
    display: flex;
    width: auto;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: var(--dark-blue);
    gap: 5px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .dash-logo img{
    width: 30px;
  }
  .dash-menu{
    display: none;
  }
 
.dash-menu-show{
    display: block;
}

.settings-menu-show li{
    display: block;
}

.dash-menu-open{
display: block;
transition: 0.3s;
}
.dash-menu-close{
  display: none;
  transition: 0.3s;
}
.logout-icon{
    display: block;
   } 
   .logout-button{
    display: none;
   } 
  
ul.dash-menu-ul div{
    top:80px;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--dark-blue);
    color: #fff;
    font-weight: bold;
    transition: 0.3s;
    z-index: 10;
    padding:20px;
    display: flex;
    flex-direction: column;
}

ul.dash-menu-ul li{
    margin-top: 0px;
    padding: 2px;
    text-transform: uppercase;
  }   
  .dash-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    padding: 5px; 
  }
}

.wallet_div{
    display: flex;
    width: 400px;
    margin: auto;

}
.dashboard{
    margin-top: 20px;
    margin-bottom: 20px;
    background-color:#fff;
    display: grid;
}

.table-container{
    width: 80%;
    min-height: 100vh;
    height: auto;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
   margin-bottom: auto;
    padding: 20px;
}
.dashboard .container{
    width:1200px;
    min-height: 100vh;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5;
}

.dash-right, .dash-left{
  padding:80px 100px;
  background-color: #fff;
}

.dash-left-bg{
    background-color:antiquewhite;
  }
.small-card, .medium-card, .large-card{
    width: 400px;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 40px auto;
}

.flex-out{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.grid-out{
    display: grid;
    align-items: center;
    gap: 2px;
}

.small-card img, .medium-card img, .large-card img{
    width:40px;
}
.small-icon{
    width:30px;
    height: 30px;
}
.small-card h3, .medium-card h3, .large-card h3{
   font-size: 20px;
   font-weight: 800;
   margin: 10px 0;
   color: var(--dark-blue);
}
.card-type h3{
    font-size: 30px;
    font-weight: 800;
    margin: 10px 0;
    color: var(--dark-blue);
    text-transform: capitalize;
 }
.medium-card p, .large-card p{
display: flex;
justify-content: space-between;
margin: 30px 10px;
}
.large-card p{
    line-height: 25px;
}

.medium-card span.bal{
font-weight: 600;
font-size: 30px;
}

.medium-card span .signup{
   cursor: pointer;
    }

.flat-card{
    display: flex;
    gap: 10px;
    margin: 50px auto;
}
.flat-card-grid{
    display: grid;
    margin: 20px 0;
    padding: 30px;
    gap: 5px;
    background-color: antiquewhite;
    border-radius: 10px;
}
.card-type{
   display: flex;
   margin: 20px 0;
   justify-content:space-evenly;
   justify-items: center;
   align-items: center;
  
}
.flat-card-details{
    display: flex;
    justify-content: space-around;
    gap: 10px;
}
.flat-card-details .card-figure{
padding:0px;
border-radius: 7px;
background-color: aquamarine;
width:30px;
}
.flat-card-details .card-number{
padding: 10px;
font-size: 16px;
font-weight: normal;
letter-spacing:2px;
}


 
@media screen and (max-width:992px){
  
.dashboard{
    margin-top: 20px;
    margin-bottom: 20px;
    background-color:#fff;
    display: grid;
}

.dashboard .container{
    width:100%;
    height: auto;
    margin: auto;    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
} 

.dash-right, .dash-left{
    padding:10px;
    background-color: #fff;
  }

  
.small-card, .medium-card, .large-card{
    width: 350px;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 40px auto;
}

}  


@media screen and (max-width:835px){
  
    .dashboard{
        margin-top: 10px;
        margin-bottom: 00px;
        background-color:#fff;
        display: grid;
    }
    
    .dashboard .container{
        width:100%;
        height: auto;
        margin: auto;    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    } 
    
    .dash-right, .dash-left{
        padding:10px;
        background-color: #fff;
        width: 100%;
        margin: auto;
      }
    .dash-right{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
       
    
    .small-card, .medium-card, .large-card{
        width: 80%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }
    .medium-card{
        width: 80%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }
    }  

        
@media screen and (max-width:600px){
  
    .dashboard{
        margin-top: 10px;
        margin-bottom: 10px;
        background-color:#fff;
        display: grid;
    }
    
    .dashboard .container{
        width:100%;
        height: auto;
        margin: auto;    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    } 
    
    .dash-right, .dash-left{
        padding:10px;
        background-color: #fff;
        width: 100%;
        margin: auto;
      }
    .dash-right{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 90%;
    }
       
    
    .small-card, .medium-card, .large-card{
        width: 80%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }
    .medium-card{
        width: 80%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }

    }  

    
@media screen and (max-width:600px){
  
      .dash-right, .dash-left{
        padding:10px;
        background-color: #fff;
        width: 100%;
        margin: auto;
      }
    .dash-right{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 90%;
    }
       
    
    .small-card, .medium-card, .large-card{
        width: 90%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }
    .medium-card{
        width: 90%;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 30px auto;
    }

    }  


      
@media screen and (max-width:440px){
  
    .dash-right, .dash-left{
      padding:5px;
      background-color: #fff;
      width: 100%;
      margin: auto;
    }
  .dash-right{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 5px;
      width: 100%;
  }
     
  
  .small-card, .medium-card, .large-card{
      width: 100%;
      border-radius: 10px;
      background-color: #fff;
      padding: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin: 30px auto;
  }
  .medium-card{
      width: 100%;
      border-radius: 10px;
      background-color: #fff;
      padding: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin: 20px auto;
  }
  }  