/* html {
    font-family: arial;
    font-size: 18px;
} */
.fees {
    width: 100%;
}

.fees td {
    border: 1px solid #1b4d89;
    padding: 15px;
}

.fees .bgb {

    background: #032943;
    background-color: #032943;
    color: white;
}

.fees thead {
    font-weight: bold;
    text-align: center;
    background: #1b4d89;
    color: white;
}

.fees table {
    border-collapse: collapse;
    width: 100%;
}

.fees .footer {
    text-align: right;
    font-weight: bold;
}

.fees tbody>tr:nth-child(odd) {
    background: #D1D0CE;
}